import request from '@/utils/request'
import qs from 'qs'
// 回显个人资料
export function automatically (id) {
  let data = {
    id
  }
  data = qs.stringify(data)
  return request({
    url: '/author/showauthor',
    method: 'post',
    data
  })
}
//查询余额
export function strBalance (id) {
  let data = {
    id
  }
  data = qs.stringify(data)
  return request({
    url: '/author/ShowAuthorYue',
    method: 'post',
    data
  })
}

// 提现记录
export function TxHistory (aid,page,limit) {
  let data = {
    aid,page,limit
  }
  data = qs.stringify(data)
  return request({
    url: '/author/TxHistory',
    method: 'post',
    data
  })
}
// 读取word内容
export function upload (file) {
  let data = {
    file
  }
  data = qs.stringify(data)
  return request({
    url: '/PoiWord/upload',
    method: 'post',
    data
  })
}