<template>
  <div>
    <div class="authortitle">投稿</div>

    <div style="padding: 20px">
      <div class="form-title">文章信息:</div>
      <el-form
        ref="form"
        :rules="rules"
        :model="form"
        label-width="80px"
        style="width: 60%"
      >
        <el-form-item label="稿件">
          <el-upload
            class="upload-demo"
            ref="upload"
            :action="path"
            :file-list="fileList"
            :data="form"
            :on-change="changfile"
            :on-success="uploadsuccsee"
            :before-upload="beforeUpload"
            :on-exceed="handleExceed"
            :auto-upload="false"
            accept=".doc,.docx"
            :on-remove="handleRemove"
            :before-remove="beforeRemove"
            multiple
            :limit="1"
          >
            <div class="downloadfj" slot="trigger">
              <i class="el-icon-paperclip"></i>点击上传附件
            </div>
            <div slot="tip" class="el-upload__tip">只能上传单个word文件</div>
          </el-upload>
        </el-form-item>
        <el-form-item label="论题" prop="title">
          <el-input v-model="form.title" placeholder="请输入论题"></el-input>
        </el-form-item>
        <el-form-item label="摘要">
          <el-input
            v-model="form.summary"
            placeholder="请输入摘要"
            type="textarea"
            resize="none"
          ></el-input>
        </el-form-item>
        <el-form-item label="关键词">
          <el-input
            v-model="form.keyword"
            placeholder="请输入关键词"
            type="textarea"
            resize="none"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            v-model="form.remarks"
            placeholder="请输入备注"
            type="textarea"
            resize="none"
          ></el-input>
        </el-form-item>
        <div class="form-title">选择期刊:</div>
        <el-form-item label="选择期刊">
          <el-button @click="selectqk" :disabled="select"
            >{{ select ? qkname : "点击选择期刊" }}
          </el-button>
          <el-button v-if="select" @click="selectqk" icon="el-icon-refresh-left"
            >更换
          </el-button>
          <!-- <div v-if="select">
                      已选投递期刊：<span>{{ qkname }}</span>
                    </div> -->
          <!-- <div v-if="select">期刊收录:&nbsp;&nbsp;&nbsp;{{title}}</div> -->
        </el-form-item>
        <el-form-item label="投稿属性" prop="attribute">
          <el-select
            v-model="form.attribute"
            placeholder="请选择投稿属性"
            clearable
            @click.native="myAttribute('form')"
          >
            <el-option label="普通" value="0"></el-option>

            <el-option label="加急" value="1" v-show="showPrise"></el-option>
          </el-select>
        </el-form-item>
        <div class="form-title">作者信息:</div>
        <el-form-item label="姓名" prop="editor">
          <el-input v-model="form.editor"></el-input>
        </el-form-item>
        <el-form-item label="电话" prop="phone">
          <el-input v-model="form.phone" placeholder="请输入电话"></el-input>
        </el-form-item>
        <el-form-item label="单位" prop="unitt">
          <el-input v-model="form.unitt" placeholder="请输入单位"></el-input>
        </el-form-item>
        <el-form-item label="地区" prop="region">
          <v-distpicker
            hide-area
            ref="distpicker"
            @selected="showregin"
          ></v-distpicker>
        </el-form-item>
        <el-form-item label="详细地址" prop="address">
          <el-input
            v-model="form.address"
            placeholder="请输入详细地址"
          ></el-input>
        </el-form-item>
        <el-form-item label="邮编" prop="postcode">
          <el-input v-model="form.postcode" placeholder="请输入邮编"></el-input>
        </el-form-item>
        <el-form-item label="简介">
          <el-input
            v-model="form.briefintroduction"
            placeholder="请输入作者简介"
          ></el-input>
        </el-form-item>
        <el-form-item label="">
          <el-checkbox v-model="checked"></el-checkbox>
          <span @click="xieyi" class="tiaokuan"> 同意投稿协议 </span>
        </el-form-item>
        <el-form-item label="">
          <el-button
            type="danger"
            @click="submitUpload('form')"
            :disabled="jybtn"
            >投稿
          </el-button>
        </el-form-item>
        <!-- <el-form-item label="">
                  <el-button
                    type="danger"
                    @click="submitUpload('form')"
                    :disabled="jybtn"
                    >投稿</el-button
                  >
                </el-form-item> -->
      </el-form>
    </div>
    <el-dialog
      title="阅读协议"
      :visible.sync="dialogVisible"
      width="60%"
      :show-close="false"
      :close-on-click-modal="false"
      center
    >
      <div
        class="agreement"
        v-for="(item, index) in agreementList"
        :key="index"
      >
        <div class="agreement-title">{{ item.agreement_name }}</div>
        <div class="agreement-content" v-html="item.agreement_content"></div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="readAgreement"
          :disabled="agreementTime > 0"
          ><span v-if="agreementTime > 0">{{ agreementTime + "s" }}</span
          >已阅读并同意协议</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import VDistpicker from "v-distpicker";
import { upload } from "../../../API/ZZD/storage";
import { applyUrgent, isUrgent } from "../../../API/QKD/setting";
import {
  agreementByType,
  isFirstLogin,
} from "../../../API/homepage/agreement.js";

export default {
  components: {
    VDistpicker,
  },

  data() {
    //验证手机号的规则;
    var checkMobile = (rule, value, cb) => {
      // 验证手机号的正则表达式
      const regMobile =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (regMobile.test(value)) {
        return cb();
      }
      cb(new Error("请输入合法的手机号"));
    };
    return {
      dialogVisible: false,
      adjpg: require("../../../assets/image/adjpg.jpg"),
      agreementTime: 15,
      agreementContext: "",
      agreementList: [],
      form: {
        title: "",
        summary: "",
        keyword: "",
        remarks: "",
        attribute: "",
        qk_id: "",
        editor: "",
        phone: "",
        unitt: "",
        region: "",
        address: "",
        postcode: "",
        briefintroduction: "",
        author_id: "",
      },
      showPrise: false,
      qkname: "", //标题
      title: "", //期刊收录
      checked: false,
      fileList: [],
      province: "",
      city: "",
      path: "",
      jybtn: true,
      file: "",
      fileLists: "", //接收文流
      status: "",
      filename: "",
      select: false,
      rules: {
        editor: [
          { required: true, message: "请输入作者姓名", trigger: "blur" },
        ],
        phone: [
          { required: true, message: "请输入电话号码", trigger: "blur" },
          {
            validator: checkMobile,
            message: "请输入正确的手机号码",
            trigger: "blur",
          },
        ],
        attribute: [
          { required: true, message: "请选择订单属性", trigger: "blur" },
        ],
        title: [{ required: true, message: "请输入论题", trigger: "blur" }],
        unitt: [
          {
            required: true,
            message: "请输入单位地址",
            trigger: "blur",
          },
        ],
        region: [
          {
            required: true,
            message: "请选择省市",
            trigger: "blur",
          },
        ],
        address: [
          {
            required: true,
            message: "请输入详细地址",
            trigger: "blur",
          },
        ],
        postcode: [
          {
            required: true,
            message: "请输入邮编",
            trigger: "blur",
          },
        ],
      },
    };
  },
  mounted() {
    this.path = this.URL + "/author/addworks";
  },
  watch: {
    $route(to, from) {
      //监听路由是否变化
      if (to.path == "/") {
        //跳转到哪个页面
        // location.reload()
        // sessionStorage.clear()
      }
    },
  },
  //  接收选择稿件的收录和标题-还有 id
  activated() {
    if (sessionStorage.getItem("tgqkname") || sessionStorage.getItem("title")) {
      this.qkname = sessionStorage.getItem("tgqkname");
      this.title = sessionStorage.getItem("title"); // 收录
      this.select = true;
      this.form.qk_id = sessionStorage.getItem("tgqk");
    }
    this.form.author_id = localStorage.getItem("ZZId");
    console.log(this.status);
  },
  methods: {
    selectqk() {
      //跳转选择期刊
      this.$router.push("/Pending/contribute/selectmagzine");
    },

    myAttribute(formName) {
      var id = sessionStorage.getItem("tgqk");
      isUrgent(id, 1, 1).then((res) => {
        if (res.data.data[0].isUrgent == 2) {
          this.showPrise = true;
        } else {
          this.showPrise = false;
        }
      });
    },
    submitUpload(formName) {
      //文件上传

      // console.log(formName,'——————————————投稿中订单————————————————')
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.fileList == "") {
            this.$message.error("未上传稿件");
          } else if (!sessionStorage.getItem("tgqkname")) {
            this.$message.error("未选择投递期刊");
          } else {
            this.$refs.upload.submit();
            // this.fileList = [];
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    uploadsuccsee(res) {
      //文件上传成功后，清除session
      if (res.code == 0) {
        sessionStorage.removeItem("tgqkname"); // 名字删除
        sessionStorage.removeItem("title"); // 收录
        this.form = {};
        this.qkname = "";
        this.title = "";
        this.$refs.upload.clearFiles();
        this.$refs["distpicker"].currentProvince = "";
        this.$refs["distpicker"].currentCity = "";
        this.fileList = [];
        this.province = "";
        this.city = "";
        // sessionStorage.clear()
        this.$message.success("上传成功");
        this.$router.push({ path: "/Pending/contributeorder/?id=1" });
        setTimeout(function () {
          // location.reload()
          // sessionStorage.clear()
        }, 100);
      } else {
        sessionStorage.removeItem("tgqkname"); // 名字删除
        sessionStorage.removeItem("title"); // 收录
        this.$message.error("投稿失败");
        setTimeout(function () {
          location.reload();
        }, 2000);
      }
    },

    // 限制上文件数量
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    //移除文件
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
      if (file.name == "") {
        that.form.summary = "";
        that.form.summary = ""; // 读取摘要
        that.form.keyword = ""; // 读取关键词
      }
    },
    beforeUpload(file) {
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension = testmsg === "doc";
      const extension2 = testmsg === "docx";
      // const isLt2M = file.size / 1024 / 1024 < 10
      if (!extension && !extension2) {
        this.$message({
          message: "上传文件只能是 doc、docx格式!",
          type: "warning",
        });
      }
      return extension || extension2;
    },
    changfile(file, fileList) {
      //文件上传选择文件后触发的
      console.log(this.filename);
      this.filename = file.name;
      let that = this;
      that.fileList = fileList;
      that.file = file;

      // 读取文件的摘要

      var param = new FormData(); //创建form对象

      param.append("file", that.file.raw);

      that
        .$axios({
          method: "post",
          url: "/PoiWord/upload",
          data: param,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (res) {
          console.log(res);
          if (res.data.code == -1) {
            that.$message.error(res.data.msg);
          }
          that.form.title = res.data.data.bt; //读取标题
          that.form.summary = res.data.data.zy; // 读取摘要
          that.form.keyword = res.data.data.gjc; // 读取关键词
          if (that.file.raw == "") {
            that.form.summary = "";
            that.form.summary = ""; // 读取摘要
            that.form.keyword = ""; // 读取关键词
          }
        });
    },

    xieyi() {
      //  this.$router.push({path: "/hereof"});
      agreementByType(4).then((res) => {
        this.dialogVisible = true;
        this.agreementTime = 15;
        this.agreementCode = 0;
        this.agreementList = res.data.data;
        this.agreementContext = setInterval((res) => {
          if (this.agreementTime == 0) {
            return;
          } else {
            this.agreementTime--;
          }
        }, 1000);
        this.agreementId = localStorage.getItem("ZZId");
        console.log();
      });
    },
    readAgreement() {
      this.dialogVisible = false;
      isFirstLogin(this.agreementCode, this.agreementId).then();
      //  this.$router.push("/homePage");
    },
    showregin(e) {
      this.form.region = e.province.value + e.city.value;
    },
  },
  watch: {
    //监测是否同意了协议
    checked(val) {
      this.jybtn = !val;
    },
  },
};
</script>

<style>
.authortitle {
  padding: 20px;
  border-bottom: 1px solid #e6e6e6;
}

.form-title {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 40px;
}

.downloadfj {
  width: 220px;
  height: 40px;
  background: #f7f7f7;
  border-radius: 4px;
  border: 1px solid #cccccc;
  line-height: 40px;
  font-size: 14px;
  cursor: pointer;
}

.tiaokuan {
  color: blueviolet;
  cursor: pointer;
}
</style>