
import request from '@/utils/request'
import qs from 'qs'

export function agreementByType (agreementType) {
  let data = {
    agreementType 
  }
  data = qs.stringify(data);
  return request({
    url: '/agreement/agreementByType?agreementType=' + agreementType ,
    method: 'get'
  })
}
export function isFirstLogin (code,id ) {
  let data = {
    code,id
  }
  data = qs.stringify(data);
  return request({
    url: '/FirstLogin/isFirstLogin',
    method: 'post',
    data
  })
}